<template>
  <div class="region">
    <!--
    <div class="box-jumpto-store">
      <span>门店代码</span>
      <el-select v-model="company_id" filterable ref="select"
          :clearable="true"
          @visible-change="onSelInput"
          @hook:mounted="onSelInput"
          placeholder="请输入门店代码或店名">
            <el-option v-for="item in companyOpts"
              :key="item.id"
              :value="item.id"
              :label="item.name">
            </el-option>
        </el-select>
      <a class="btn" @click="jumpToStore">去门店</a>
    </div>
    <div class="line"></div>
    -->
    <div v-if="simpleTpl" class="region">
        <div class="item">
          <TwoLevelListDTS :tableData="tableDataDTS" nextPage="/dashboard/area" queryKey="area_id"></TwoLevelListDTS>
        </div>
    </div>
    <div v-else>
        <div class="item">
          <div class="title">得分公式</div>
          <div class="box-total" v-if="scoreData">
            <p>
            <strong class="a">{{scoreData.a}}</strong>
            <span class="a-p">展厅接待得分<i>(A)</i></span>
            </p>
            <p class="flag">=</p>
            <p>
            <strong>{{scoreData.b}}</strong>
            <span>系统使用得分 <i class="i-question" @click="popFormula=true"></i> <i>B ({{scoreData.b_percent}})</i></span>
            </p>
            <p class="flag">+</p>
            <p>
            <strong>{{scoreData.c}}</strong>
            <span>接待质量得分<i>C ({{scoreData.c_percent}})</i></span>
            </p>
          </div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="title">核心指标</div>
          <div class="box-core">
            <div class="main" v-for="(item, index) in entryData" :key="index" >
              <div class="value">
                <strong>{{ item.value }}</strong> {{['组','组','组','分'][index]}}
              </div>
              <div class="label">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="item">
        <ThreeLevelList v-if="threeTpl" :tableData="tableData" :tableQualityData="tableQualityData" nextPage="/dashboard/area" queryKey="area_id"/>
          
          <TwoLevelList v-else :tableData="tableData" nextPage="/dashboard/area" queryKey="area_id"></TwoLevelList>
        </div>

        <el-dialog
          title=" "
          :visible.sync="popFormula"
          class="pop-formula" >
           <div class="box-formula">
             <p>{{formula.b_formula}}</p>
             <!--
             <p>
               <span>人员使用得分=</span>
               <span class="division">
                 <strong>{{formula.b1_numerator}}</strong>
                 <strong>{{formula.b1_denominator}}</strong>
               </span>
               <span>{{formula.b1_right}}</span>
             </p>
             <p>
               <span>人员匹配得分=</span>
               <span class="division">
                 <strong>{{formula.b2_numerator}}</strong>
                 <strong>{{formula.b2_denominator}}</strong>
               </span>
               <span>{{formula.b2_right}}</span>
             </p>
             -->
           </div>
          <span slot="footer" class="dialog-footer">
          </span>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import { postData } from "@/dbCommon/api/index";
import util from "@/dbCommon/utils/index";
import TwoLevelList from '@/components/TwoLevelList'
import TwoLevelListDTS from '@/components/TwoLevelListDTS'
import ThreeLevelList from '@/components/ThreeLevelList'
import { jump } from "@/dbCommon/utils/index";

export default {
  components: {
    TwoLevelList,
    TwoLevelListDTS,
    ThreeLevelList,
  },
  
  data() {
    return {
      entryData: [],
      listData: [],
      scoreData: {},
      systemData: [],
      formula: {},
      popFormula: false,
      tableData: {},
      tableDataDTS: {},
      simpleTpl: util.isDTS,
      tableQualityData: [],
      threeTpl: util.showThree,
      companyOpts: [],
      company_id: '',
    }
  },
  methods: {
    onSelInput() {
      this.$nextTick(() => {
          const {select} = this.$refs;
          const input = select.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
      });
    },
    jumpToStore() {
      const query={...this.$store.state.queryDB,company_id:this.company_id};
      jump("/dashboard/store",this, query);
    },
    getQualityData () {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.queryDB };
      postData('/api/board/qualityTestingRegion', query).then(res => {
        this.tableQualityData = res.data;
      });
    },
    async getDataBySearch() {
      const query = { is_mobile:1,...this.$route.query, ...this.$store.state.queryDB };
      if(query.brand_id) {
        this.simpleTpl = query.brand_id == util.DTS_ID;
        this.threeTpl = util.showThree && (query.brand_id == util.DAZHONG_ID);
      }
      this.loading = true;
      const { data = {} } = await postData("/api/board/regionNew", query);
      const {
        list,
        title_hall,
        title_web,
        core,
        formula,
        ...scoreData
      } = data || {};
 
      this.entryData = [
      { label: "接待量", value: core.count },
        { label: "首次进店量", value: core.first },
        { label: "再次进店量", value: core.again },
        { label: "展厅接待得分", value: core.total },
      ];

      this.listData = list;
      this.scoreData = scoreData;
      this.formula = formula;

      this.tableData = util.formatVolvoList(list, title_hall);
      this.tableDataDTS = util.formatVolvoListDTS(list, title_hall);
      
      this.loading = false;

      if (this.threeTpl) {
        this.getQualityData();
      }
    },
  },
  async mounted() {
    this.getDataBySearch();
  },
};
</script>
<style lang="less" scoped>

</style>


